import React, { useState } from 'react';
import './App.css';

function App() {
  const [userInput, setUserInput] = useState('');
  const [apiResponse, setApiResponse] = useState('roses are red\ni have the answers\nand so do you');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter' && userInput.trim() !== '') {
      setIsLoading(true);
      try {
        const response = await fetch(`https://script.google.com/macros/s/AKfycbyjxYWW9ub9CorAAq67uMkKYXrZPdPyGKe7uvQun_Xu3VC--BrGsZSTzQZIbQkoj-SN/exec?question=${encodeURIComponent(userInput)}`);
        console.log("response", response)
        if (response.ok) {
          const data = await response.json();
          console.log(data)
          setApiResponse(data.response || 'No response received.');
        } else {
          setApiResponse('Error: Unable to fetch response.');
        }
      } catch (error) {
        setApiResponse(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }

      setUserInput(''); // Clear input after submission
    }
  };

  return (
    <div className="App">
      <div className='Nav'>
        <h1>ò<i style={{verticalAlign:"middle", fontSize: '20px', fontWeight: 'lighter'}}>llie</i>.co</h1>
        <img style={{margin:'0'}} src={require('./image.png')}></img>
        <div style={{margin:'0'}}><i style={{fontSize:"20px"}}>Speed, Science, Serenity</i></div>
      </div>
      {isLoading && <div style={{marginLeft:'20px'}} className="loading-bar"></div>}
      {!isLoading &&
      <div className='Answer'>
        <p style={{ whiteSpace: 'pre-wrap' }}>{apiResponse}</p>
      </div>
      }
      <div>
      <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Ask me anything..."
        />
      </div>
    </div>
  );
}

export default App;
